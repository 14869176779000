<template>
	<Suspense>
		<template #default>
			<IonApp>
				<IonSplitPane content-id="main-content">
					<ion-menu v-if="autenticado" content-id="main-content" type="overlay">
						<ion-content>
							<ion-list id="inbox-list">
								<div class="profile">
									<div style="line-height: 20px">
										<ion-note class="user">{{ $store.state.usuario.name }}</ion-note><br>
										<ion-note>{{ $store.state.usuario.email }}</ion-note><br>
									</div>
								</div>

								<ion-menu-toggle auto-hide="false">
									<ion-item-group>
										<ion-item-divider>
											<ion-label>Módulos</ion-label>
										</ion-item-divider>

										<ion-item button @click="$router.push({ name: 'dashboard.index' })" lines="none" detail="false" class="hydrated">
											<ion-icon name="home-outline" slot="start"></ion-icon>
											<ion-label>Painel</ion-label>
										</ion-item>

										<ion-item
											v-if="$store.state.usuario.permissions['view_requests_for_anyone'] || $store.state.usuario.permissions['view_requests_for_yourself']"
											button
											@click="$router.push({ name: 'solicitacoes.index' })"
											lines="none"
											detail="false"
											class="hydrated"
										>
											<ion-icon name="documents-outline"></ion-icon>
											<ion-label>Solicitações</ion-label>
										</ion-item>

										<ion-item
											v-if="$store.state.usuario.permissions['view_users']"
											button
											@click="$router.push({ name: 'usuarios.index' })"
											lines="none"
											detail="false"
											class="hydrated"
										>
											<ion-icon name="lock-closed-outline"></ion-icon>
											<ion-label>Usuários</ion-label>
										</ion-item>
									</ion-item-group>

									<ion-item-group>
										<ion-item button @click="logout" lines="none" detail="false" class="hydrated">
											<ion-icon
												name="exit-outline"
												slot="start"
												@click="logout"
											></ion-icon>
											<ion-label>Sair</ion-label>
										</ion-item>
									</ion-item-group>
								</ion-menu-toggle>
							</ion-list>
						</ion-content>
					</ion-menu>

					<ion-router-outlet id="main-content"></ion-router-outlet>
				</IonSplitPane>
			</IonApp>
		</template>
		<template #fallback>
			<div><!-- todo --></div>
		</template>
	</Suspense>
</template>

<script>
	//Ícones Ionic
	import {
		homeOutline,
		exitOutline,
		lockClosedOutline,
		documentsOutline
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"home-outline": homeOutline,
		"exit-outline": exitOutline,
		"lock-closed-outline": lockClosedOutline,
		"documents-outline": documentsOutline,
	});

	//Componentes Ionic
	import {
		IonApp,
		IonContent,
		IonItem,
		IonLabel,
		IonList,
		IonMenu,
		IonMenuToggle,
		IonRouterOutlet,
		IonSplitPane,
		IonItemDivider,
		IonIcon,
		IonItemGroup,
		IonNote,
		alertController
	} from '@ionic/vue';

	export default {
		name: 'App',

		data() {
			return {
				refreshing: false,
				registration: null,
			}
		},

		methods: {
			logout() {
				this.$store.dispatch(`logout`);
			},

			refreshApp() {
				//Garante enviar apenas uma mensagem de 'pular espera' se o SW estiver esperando
				if (!this.registration || !this.registration.waiting) return;
				//Envia mensagem para o SW para pular a espera e ativar o novo SW
				this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
			},

			async updateAvailable(event) {
				const vm = this;

				//Armazena o registro do SW para que possamos enviar uma mensagem
				this.registration = event.detail;

				const alert = await alertController
					.create({
						header: 'Nova versão disponível',
						message: "Sua página será atualizada para fazer o upgrade!",
						buttons: [
							{
								text: 'OK',
								handler: function () {
									vm.refreshApp();
								}
							},
						],
					});
				return alert.present();
			}
		},

		computed: {
			autenticado() {
				return this.$store.state.usuario !== undefined;
			}
		},

		created() {
			//Ouve o evento personalizadoswUpdated a partir do registro do SW
			document.addEventListener('swUpdated', this.updateAvailable, { once: true });

			//Previne várias atualizações
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				//Não deixa ir adiante se já estiver atualizando
				if (this.refreshing) return;

				this.refreshing = true;
				window.location.reload();
			});
		},

		components: {
			IonApp,
			IonContent,
			IonItem,
			IonLabel,
			IonList,
			IonMenu,
			IonMenuToggle,
			IonRouterOutlet,
			IonSplitPane,
			IonItemDivider,
			IonIcon,
			IonItemGroup,
			IonNote
		},
	}
</script>

<style scoped>
	.profile {
		margin-left: 15px;
		margin-top: 20px;
		margin-bottom: 10px;
		font-weight: 500;
	}

	@media (prefers-color-scheme: dark) {
		.profile .company,
		.profile .user {
			color: white;
		}
	}

	ion-icon {
		margin-right: 10px;
	}
</style>
