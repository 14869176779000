import store from "@/store";
import {acessoNegado} from "@/helpers";

export default [
	{
		path: '/usuarios',
		name: 'usuarios.index',
		beforeEnter: (to, from, next) => {
			if (!store.state.usuario.permissions['view_users']) {
				acessoNegado();
				return;
			}

			return store.dispatch('checkAuthorization', next)
		},
		component: () => import("../../views/usuarios/index"),
		meta: {
			title: 'Usuários'
		}
	},
	{
		path: '/usuarios/criar',
		name: 'usuarios.create',
		beforeEnter: (to, from, next) => {
			if (!store.state.usuario.permissions['create_users']) {
				acessoNegado();
				return;
			}

			store.dispatch('checkAuthorization', next)
		},
		component: () => import("../../views/usuarios/edit"),
		meta: {
			title: 'Criação Usuário'
		}
	},
	{
		path: '/usuarios/:id',
		name: 'usuarios.edit',
		beforeEnter: (to, from, next) => {
			if (store.state.usuario.permissions['view_users'] || store.state.usuario.permissions['update_users']) {
				store.dispatch('checkAuthorization', next)
			} else {
				acessoNegado();
				return;
			}
		},
		component: () => import("../../views/usuarios/edit"),
		meta: {
			title: 'Edição Usuário'
		}
	}
];
