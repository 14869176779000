import store from "@/store";
import {acessoNegado} from "@/helpers";

export default [
	{
		path: '/solicitacoes',
		name: 'solicitacoes.index',
		beforeEnter: (to, from, next) => {
			if (
				store.state.usuario.permissions['view_requests_for_anyone']
				|| store.state.usuario.permissions['view_requests_for_yourself']
			) {
				return store.dispatch('checkAuthorization', next)
			} else {
				acessoNegado();
				return;
			}
		},
		component: () => import("../../views/solicitacoes/index"),
		meta: {
			title: 'Solicitações'
		}
	},
	{
		path: '/solicitacoes/criar',
		name: 'solicitacoes.create',
		beforeEnter: (to, from, next) => {
			if (
				store.state.usuario.permissions['create_requests_for_anyone']
				|| store.state.usuario.permissions['create_requests_for_yourself']
			) {
				return store.dispatch('checkAuthorization', next)

			} else {
				acessoNegado();
				return;
			}
		},
		component: () => import("../../views/solicitacoes/edit"),
		meta: {
			title: 'Criação Solicitação'
		}
	},
	{
		path: '/solicitacoes/:id',
		name: 'solicitacoes.edit',
		beforeEnter: (to, from, next) => {
			if (
				store.state.usuario.permissions['view_requests_for_anyone']
				|| store.state.usuario.permissions['view_requests_for_yourself']
				|| store.state.usuario.permissions['update_requests_for_anyone']
				|| store.state.usuario.permissions['update_requests_for_yourself']
			) {
				store.dispatch('checkAuthorization', next)
			} else {
				acessoNegado();
				return;
			}
		},
		component: () => import("../../views/solicitacoes/edit"),
		meta: {
			title: 'Edição Solicitação'
		}
	}
];
