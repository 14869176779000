import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store';
import Usuarios from './routes/usuarios';
import Solicitacoes from './routes/solicitacoes';

const routes = [
	{
		path: '',
		name: 'dashboard.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import ('../views/dashboard/index.vue'),
		meta: {
			title: 'Painel'
		}
	},
	{
		path: '/login',
		name: 'login.index',
		component: () => import ('../views/login/index.vue'),
		meta: {
			title: 'Login'
		}
	},
	...Usuarios,
	...Solicitacoes,
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: () => import("../views/404/index"),
		meta: {
			title: "Não Encontrado (404)"
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router;
