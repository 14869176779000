import { library } from '@fortawesome/fontawesome-svg-core';

//Brands

//Regular
import {
	faHandPointRight
} from '@fortawesome/free-regular-svg-icons';

//Solid
import {
	faEraser,
	faEdit,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
	faEraser,
	faEdit,
	faHandPointRight
);
export default FontAwesomeIcon;
