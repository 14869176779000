import { createApp } from 'vue';
import App from './App.vue'
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import router from './router';
import './registerServiceWorker';
import store from './store';

/* VueFire */
import { firestorePlugin } from 'vuefire';

/* Lodash */
import _ from 'lodash';

import VueTranslate from 'vue-simple-translator';
import translations from '@/translations.json';

//Ícones
import FontAwesomeIcon from "./icons";

//Google Recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3';

//v-money
import money from 'v-money';

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(firestorePlugin)
	.use(store)
	.use(money, {precision: 4})
	.use(VueReCaptcha, {
		siteKey: '6LeNCdkaAAAAABSpAdCA9z0_zdXwnFFnF2HddFY1',
		loaderOptions: {
			size: 'invisible',
			autoHideBadge: true
		}
	})
	.use(VueTranslate, {
		availableLanguages: {
			pt_BR: 'Português do Brasil'
		},
		defaultLanguage: 'pt_BR',
		translations: translations
	})
	.component('font-awesome-icon', FontAwesomeIcon)
	.directive("lowercase", {
		updated: function (el) {
			el.value = el.value
				? el.value.toLowerCase()
				: el.value;
		}
	})
	.directive("uppercase", {
		updated: function (el) {
			el.value = el.value
				? el.value.toUpperCase()
				: el.value
		}
	});

app.config.globalProperties.$_ = _;

router.isReady().then(() => {
	app.mount('#app');
});
