import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
// import {toast} from "@/helpers";

const app = firebase.initializeApp({
    apiKey: "AIzaSyBHleJa3kU86CG95tRppDqpxlou17sJ6Bk",
    authDomain: "solicitacao-cotacao-fretes.firebaseapp.com",
    projectId: "solicitacao-cotacao-fretes",
    storageBucket: "solicitacao-cotacao-fretes.appspot.com",
    messagingSenderId: "888705923142",
    appId: "1:888705923142:web:9484486d2bd13a9686f247",
    measurementId: "G-WNEG4E0YVG"
});

firebase
    .auth()
    .useDeviceLanguage();

const db = app.firestore();
db.settings({cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED});
// db.enablePersistence()
//     .catch((err) => {
//         if (err.code === 'failed-precondition') {
//             toast('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
//
//         } else if (err.code === 'unimplemented') {
//             toast('The current browser does not support all of the features required to enable persistence.');
//         }
//     });

const functions = firebase
    .app()
    .functions('southamerica-east1');

if (process.env.NODE_ENV !== 'production')
    functions.useEmulator("localhost", 5001);

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const {Timestamp, GeoPoint} = firebase.firestore;
export {
    db,
    Timestamp,
    GeoPoint,
    functions
};
