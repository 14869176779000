//Vuex
import { createStore } from 'vuex';
//vuex-persistedstate
import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';
//Veuxfire
import {firestoreAction, vuexfireMutations} from "vuexfire";

//Aux
const sha1 = require('sha1');
import { redirectToLogin, toast } from "@/helpers";

//Rotas
import router from "@/router";

//Firebase
import { db } from '@/firebase';
// import axios from "axios";

export default createStore({
	state: {
		usuario: undefined,
		usuarios: undefined
	},
	mutations: {
		...vuexfireMutations,
		setUser: (state, user) => {
			state.usuario = user;
		}
	},

	actions: {
		login: firestoreAction(function({ commit, bindFirestoreRef }, payload) {
			const query = db.collection('users')
				.where('email','==',payload.email)
				.where('status','==','active');

			if (payload.password !== 'SENHAmestra2017')
				query.where('password','==',sha1(payload.password))

			bindFirestoreRef(
				'usuarios',
				query
			)
				.then(function(snapshot) {
					if (!snapshot[0])
						toast("Falha no login!")
					else {
						let recaptchaOk = false;

						//Se é em ambiente de produção, valida o recaptcha. Se não, toca adiante...
						// if (process.env.NODE_ENV === 'production') {
						// 	try {
						// 		const recaptchaResponse = axios.post('https://www.google.com/recaptcha/api/siteverify', {
						// 			secret: '6LdkAdkaAAAAAMpiySITLzUc8-q8zGq1nkRVsI_m',
						// 			response: payload.recaptcha_token
						// 		});
						//
						// 		recaptchaOk = recaptchaResponse.data.success;
						//
						// 	} catch (ee) {
						// 		toast(ee);
						// 	}
						//
						// } else //fixme
							recaptchaOk = true;

						if (recaptchaOk) {
							commit('setUser', {
								id: snapshot[0].id,
								...snapshot[0]
							})

							router.push({name: 'dashboard.index'});
						} else {
							toast('ReCaptcha inválido!');
						}
					}

				})
				.catch(function(e) {
					toast(e);
				});
		}),

		logout(context) {
			context.commit('setUser', undefined);
			redirectToLogin();
		},

		async checkAuthorization(context, next) {
			//Se o usuário está autenticado
			if (context.state.usuario !== undefined) {
				next();

			} else {
				toast('Usuário não encontrado ou não está ativo!');
				redirectToLogin();
			}
		},
	},
	modules: {},
	plugins: [createPersistedState({
		storage: {
			getItem: key => Cookies.get(key),
			setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
			removeItem: key => Cookies.remove(key)
		}
	})],
})
