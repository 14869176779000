import {toastController} from "@ionic/core";
import moment from "moment";
import {alertController} from "@ionic/vue";
import {functions} from "@/firebase";

const _ = require('lodash');

const emptyStringIfNull = (value) => !!value ? value : "";

const reactivePush = function(root, path, value) {
	let newRoot = root;
	let pathValue = _.get(newRoot, path);
	pathValue.push(value);

	root = Object.assign({}, root, newRoot);
};

const reactiveSet = function(root, path, value) {
	let newRoot = root;
	root = _.set(newRoot, path, value);
};

const reactiveDelete = function(root, path, index) {
	let newRoot = root;

	_.unset(newRoot, `${path}[${index}]`);

	let arr = _.get(newRoot, path);
	arr.splice(index, 1);

	root = newRoot;
};

const dateToString = (date) => moment(date).format('DD/MM/YYYY HH:mm:ss');

/**
 * Exibe toast.
 *
 * @param message
 * @param duration
 * @returns {Promise<void>}
 */
const toast = async function(message, duration = 2000) {
	console.log(message);

	const toast = await toastController
		.create({
			message: message,
			duration: duration
		})
	return toast.present();
};

const confirmAlert = async function(msg, yesHandler, cancelHandler = () => {}) {
	const alert = await alertController
		.create({
			header: 'Confirmação',
			message: msg,
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
					cssClass: 'secondary',
					handler: cancelHandler,
				},
				{
					text: 'Sim',
					handler: yesHandler,
				},
			],
		});
	return alert.present();
};

const acessoNegado = async function() {
	toast("Você não possui permissão para esse recurso!");
};

const redirectToLogin = function() {
	window.location = '/login';
};

const toCurrency = function(value, currency = "BRL", precision = 2) {
	return Number
		.parseFloat(value)
		.toLocaleString('pt-BR', {
			currency: currency,
			style:"currency",
			minimumFractionDigits: precision
		});
};

const enviaEmail = functions.httpsCallable('enviaEmail');

const sincronizaClientes = functions.httpsCallable('sincronizaClientes');

const enviaCopiaSolicitacao = async function(request, customer, requester, user, state, city) {
	const _city = (typeof city === 'object') ? city.name : city;
	const html =
`<strong>Estabelecimento de Origem:</strong> ${emptyStringIfNull(request.source)}<br>
<br>
<strong>Requisitante:</strong><br>
&emsp;<strong>Nome:</strong> ${emptyStringIfNull(requester.name)}<br>
&emsp;<strong>E-mail:</strong> ${emptyStringIfNull(requester.email)}<br>
<br>
<strong>Cliente:</strong><br>
&emsp;<strong>Razão Social:</strong> ${emptyStringIfNull(customer.business_name)}<br>
&emsp;<strong>Documento:</strong> ${emptyStringIfNull(customer.document)}<br>
&emsp;<strong>Telefone:</strong> ${emptyStringIfNull(customer.landline)}<br>
&emsp;<strong>Celular:</strong> ${emptyStringIfNull(customer.phone)}<br>
&emsp;<strong>E-mail:</strong> ${emptyStringIfNull(customer.email)}<br>
<br>
<strong>Entrega:</strong><br>
&emsp;<strong>Estado:</strong> ${emptyStringIfNull(state.name)}<br>
&emsp;<strong>Cidade:</strong> ${_city}<br>
&emsp;<strong>Endereço:</strong> ${emptyStringIfNull(request.address)}<br>
<br>
<strong>Pedido:</strong><br>
&emsp;<strong>Tipo de Produto:</strong> ${emptyStringIfNull(request.type)}<br>
&emsp;<strong>Quantidade:</strong> ${emptyStringIfNull(request.quantity)}<br>
&emsp;<strong>Quantidade de Paletes:</strong> ${emptyStringIfNull(request.pallets_quantity)}<br>
&emsp;<strong>Peso:</strong> ${emptyStringIfNull(request.weight)}<br>
&emsp;<strong>Total da Nota Fiscal:</strong> ${emptyStringIfNull(request.invoice_total)}<br>
&emsp;<strong>Observações:</strong> ${emptyStringIfNull(request.ps)}<br>
`;

	const cc = Array.from(new Set([
		// customer.email,

		'equipefretes@carolinasoil.com.br',
		'cotacaofretes@carolinasoil.com.br',
		requester.email,
		user.email,
	])).join(",");

	await enviaEmail({
		token: '5599f5092a604941ca6e835f82c00adf5c6a2597',
		cc: cc,
		subject: `Cotação de Fretes - CÓD. SOLICITAÇÃO: ${emptyStringIfNull(request.id)} | SOLICITANTE: ${emptyStringIfNull(requester.name)} | CLIENTE: ${emptyStringIfNull(customer.name)} | CIDADE: ${emptyStringIfNull(city.name)}/${emptyStringIfNull(state.acronym)}`,
		html: html
	});
}

export {
	reactivePush,
	reactiveSet,
	reactiveDelete,
	toast,
	dateToString,
	confirmAlert,
	redirectToLogin,
	acessoNegado,
	enviaEmail,
	sincronizaClientes,
	toCurrency,
	enviaCopiaSolicitacao
};
